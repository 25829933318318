import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './Components/Header';
import Home from './Pages/Home';
import NewDID from './Pages/NewDID';
import Manage from './Pages/Manage';
import DIDList from './Pages/DIDList';
import Login from './Pages/Login';
import { SearchProvider } from './Components/SearchContext';

function App() {
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    const checkAuth = () => {
      const authentication = localStorage.getItem('authentication');
      setAuth(!!authentication);
    };

    checkAuth(); // Initial check

    const interval = setInterval(checkAuth, 1000); 

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <BrowserRouter>
        <SearchProvider>
          {auth && <Header />}
          <Routes>
            <Route index path="/" element={<Home />} />
            <Route path="/NewDID" element={<NewDID />} />
            <Route path="/ManageDID/:did" element={<Manage />} />
            <Route path="/DIDList" element={<DIDList />} />
            <Route path="/Login" element={<Login />} />
          </Routes>
        </SearchProvider>
      </BrowserRouter>
    </div>
  );
}


export default App;
