import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, TextField, Button, useMediaQuery, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import GetAppIcon from '@mui/icons-material/GetApp';

function NewDID() {
  const authentication = localStorage.getItem('authentication');
  const [inputData, setInputData] = useState('');
  const [number, setNumber] = useState('');
  const [did, setDid] = useState('');
  const [domain, setDomain] = useState('');
  const [password, setPassword] = useState('');
  const [switchIP, setSwitchIP] = useState('');
  const [serverIPs, setServerIPs] = useState([]);
  const [selectedServerIP, setSelectedServerIP] = useState('');
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const fetchServerIPs = async () => {
      try {
        const response = await axios.get(`https://tfkroute.geekabhishek.com/did/${authentication}/dashboard`);
        const data = response.data;
        const ips = Object.keys(data).map(ip => ({ ip, count: data[ip].total }));
        setServerIPs(ips);
        const lowestDIDIP = ips.reduce((prev, current) => (prev.count < current.count) ? prev : current);
        setSelectedServerIP(lowestDIDIP.ip);
      } catch (error) {
        console.error("Error fetching server IPs:", error);
      }
    };

    fetchServerIPs();
  }, [authentication]);

  const handleDidInput = (event) => {
    setInputData(event.target.value);
  };

  const formatInputData = () => {
    const processedData = processInput(inputData);
    setNumber(processedData.number);
    setDid(processedData.did);
    setDomain(processedData.domain);
    setPassword(processedData.password);
    setSwitchIP(processedData.switchIP);
  };

  const processInput = (input) => {
    const formattedNumber = input.match(/\((\d{3})\)\s*(\d{3})-(\d{4})/) ? 
                            `1${input.match(/\((\d{3})\)\s*(\d{3})-(\d{4})/)[1]}${input.match(/\((\d{3})\)\s*(\d{3})-(\d{4})/)[2]}${input.match(/\((\d{3})\)\s*(\d{3})-(\d{4})/)[3]}` : 
                            'Not Found';

    const findDID = input.match(/VH[A-Za-z0-9]{14,}/);
    const findPass = input.match(/(?<!VH)[A-Za-z0-9]*[@!#$%^&*][A-Za-z0-9@!#$%^&*]+/);
    const findDomain = input.match(/[a-zA-Z0-9.-]+\.com\b/);
    const findSwitchIP = input.match(/\b\d{1,3}(\.\d{1,3}){3}\b/);

    return {
      number: formattedNumber, // Using the one-liner formatted number
      did: findDID ? findDID[0] : 'Not Found',
      domain: findDomain ? findDomain[0] : 'Not Found',
      password: findPass ? findPass[0] : 'Not Found',
      switchIP: findSwitchIP ? findSwitchIP[0] : 'Not Found'
    };
};

  const handleServerIPChange = (event) => {
    setSelectedServerIP(event.target.value);
  };

  const handleSubmit = async () => {
    const dataToPost = {
      number, did, domain, password, switchIP, serverIP: selectedServerIP
    };
    try {
      await axios.post(`https://tfkroute.geekabhishek.com/configure/${authentication}/NewDID`, dataToPost);
      console.log('Data submitted successfully');
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  return (
    <Grid container spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
      {isMobile ? null : <Grid item sm={0} md={3} />}
      <Grid item xs={12} md={3} style={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          label="Input Data"
          multiline
          rows={6.5}
          variant="outlined"
          fullWidth
          value={inputData}
          onChange={handleDidInput}
        />
        <Button onClick={formatInputData} variant='contained' startIcon={<GetAppIcon />}>
          Fetch Data
        </Button>
        <FormControl fullWidth style={{ marginTop: '10px' }}>
          <InputLabel>Server IP</InputLabel>
          <Select
            value={selectedServerIP}
            onChange={handleServerIPChange}
            label="Server IP"
          >
            {serverIPs.map((ipData, index) => (
              <MenuItem key={index} value={ipData.ip}>{`${ipData.ip} (${ipData.count} DIDs)`}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button onClick={handleSubmit} variant='contained' color='primary' startIcon={<SendIcon />} style={{ marginTop: '10px' }}>
          Configure
        </Button>
      </Grid>
      <Grid item xs={12} md={3} style={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          label="Number"
          variant="outlined"
          fullWidth
          value={number}
          InputProps={{ readOnly: true }}
        />
        <TextField
          label="Did"
          variant="outlined"
          fullWidth
          value={did}
          InputProps={{ readOnly: true }}
        />
        <TextField
          label="Domain"
          variant="outlined"
          fullWidth
          value={domain}
          InputProps={{ readOnly: true }}
        />
        <TextField
          label="Password"
          variant="outlined"
          fullWidth
          value={password}
          InputProps={{ readOnly: true }}
        />
        <TextField
          label="Switch IP"
          variant="outlined"
          fullWidth
          value={switchIP}
          InputProps={{ readOnly: true }}
        />
      </Grid>
      {isMobile ? null : <Grid item sm={0} md={3} />}
    </Grid>
  );
}

export default NewDID;
