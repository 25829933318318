import React from "react";
import styled from "@emotion/styled";
import { useState } from "react";
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import AccountIcon from '@mui/icons-material/AccountCircleRounded';
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
display: flex;
justify-content: center;
align-items: top;
height: 100vh;
margin-top: 20px;
form {
  height: 100px;
  display: grid;
  place-items: center;
  gap: 5%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  label {
    justify-self: left;
  }
  .placeHolder{
    position: relative;
    display: block;
    justify-self: left;
    height: 35px;
    top: -55px;
    left: 20px;
    
  }
  .Account{
    position: relative;
    height: 60px;
    width: 60px;
    left: 35%;
  }
  input {
    height: 40px;
    width: 300px;
    margin-top: 10px;
    padding: 10px;
    padding-left: 45px;
    /* ::placeholder {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.75;
      letter-spacing: 0.02857em;
    } */
  }

  #email,
  #password {
    margin-top: 0;
  }
}
`;

const SubmitBTN = {
backgroundColor: "#1976d2",
color: "#fff",
fontFamily: '"Roboto","Helvetica","Arial","sans-serif"',
fontWeight: "500",
fontSize: "0.875rem",
lineHeight: "1.75",
letterSpacing: "0.02857em",
textTransform: "uppercase",
padding: "10px",
borderRadius: "4px",
justifySelf: "center",
height: "64px",
width: "365px",
cursor: 'pointer',
};


function Login() {
    const Navigate = useNavigate();
    const [formData, setFormData] = useState({ username: '', password: '' });

    const inputHandle = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const submitHandle = async (e) =>{
        e.preventDefault();
        const dataToPost = {
            username : formData.username,
            password : formData.password,
        }
        try{
            const userDetail = await axios.post(`https://tfkroute.geekabhishek.com/Authentication/` , dataToPost)
            if(userDetail.data.authentication){
            localStorage.setItem('authentication', userDetail.data.authentication);
            localStorage.setItem('username', userDetail.data.username);
            Navigate('/');
            }

        }catch(error){
            console.error("Error sending data:", error);
        }


    }


  return (
    <div>
      <Container>
        <form onSubmit={submitHandle}>
          <div className="formHeading">
            <AccountIcon color="primary" className="Account"/>
            <h1>Welcome Back!</h1>
            <h3>Please Login to your account.</h3>
          </div>
          <label htmlFor="username">Username</label>
          <input
            type="text"
            name="username"
            id="username"
            value={formData.username}
            onChange={inputHandle}
          />
          <PersonIcon className="placeHolder" color='primary' />
          <label htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            id="password"
            value={formData.password}
            onChange={inputHandle}
          />
          <EmailIcon className="placeHolder" color='primary'/>
          <input type="submit" value="Login" style={SubmitBTN} />
        </form>
      </Container>
    </div>
  );
}

export default Login;
