import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { SearchContext } from "../Components/SearchContext"; // Adjust the path as per your project structure

function DIDList() {
  const authentication = localStorage.getItem('authentication');
  const navigate = useNavigate();
  const { searchQuery } = useContext(SearchContext);
  const [didData, setDIDData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://tfkroute.geekabhishek.com/did/${authentication}/allDetails`
        );
        setDIDData(response.data.allDIDs);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [authentication]);
  const handleRowClick = (id) => {
    navigate(`/ManageDID/${id}`);
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setOpenDeleteConfirm(true);
  };

  const handleConfirmDelete = async () => {
    try {
      // Replace with your API delete request
      await axios.delete(`https://tfkroute.geekabhishek.com/dElEtEdId/${authentication}/${deleteId}`);
      setDIDData(didData.filter(item => item.did !== deleteId)); // Remove the deleted item from the state
    } catch (error) {
      console.error("Error deleting:", error);
    }
    setOpenDeleteConfirm(false);
  };

  const handleCloseDeleteConfirm = () => {
    setOpenDeleteConfirm(false);
  };
  const handleExportCSV = () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent +=
      "ID,Number,DID,Password,Domain,ServerIP,RouteIP,Last Check Time,Status\n";
    didData.forEach((row) => {
      const rowData = [
        row.id,
        row.number,
        row.did,
        row.password,
        row.domain,
        row.server_ip,
        row.switch_ip,
        row.last_check_time,
        row.status,
      ].join(",");
      csvContent += rowData + "\n";
    });
    const encodedUri = encodeURI(csvContent);

    // Get current date and time
    const now = new Date();
    const formattedDate = now.toISOString().split("T")[0]; // YYYY-MM-DD
    const formattedTime = now.toTimeString().split(" ")[0].replace(/:/g, "-"); // HH-MM-SS

    const filename = `did_list_${formattedDate}_${formattedTime}.csv`;

    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Filtered data based on the search query
  const filteredData = didData.filter((data) => {
    const searchLower = searchQuery.toLowerCase();
    return (
      data.id.toString().toLowerCase().includes(searchLower) ||
      data.number.toLowerCase().includes(searchLower) ||
      data.did.toLowerCase().includes(searchLower) ||
      data.password.toLowerCase().includes(searchLower) ||
      data.domain.toLowerCase().includes(searchLower) ||
      data.server_ip.toLowerCase().includes(searchLower) ||
      data.switch_ip.toLowerCase().includes(searchLower) ||
      data.status.toLowerCase().includes(searchLower)
    );
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div style={{ maxWidth: "100%", overflow: "auto" }}>
      <TableContainer component={Paper} sx={{ maxHeight: "80vh" }}>
        <Table stickyHeader aria-label="DID list table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Number</TableCell>
              <TableCell>DID</TableCell>
              <TableCell>Password</TableCell>
              <TableCell>Domain</TableCell>
              <TableCell>ServerIP</TableCell>
              <TableCell>RouteIP</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((data) => (
              <TableRow key={data.id}>
                <TableCell
                  component="th"
                  scope="row"
                  onClick={() => handleRowClick(data.did)}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline dotted blue",
                  }}
                >
                  {data.id}
                </TableCell>
                <TableCell>{data.number}</TableCell>
                <TableCell>{data.did}</TableCell>
                <TableCell>{data.password}</TableCell>
                <TableCell>{data.domain}</TableCell>
                <TableCell>{data.server_ip}</TableCell>
                <TableCell>{data.switch_ip}</TableCell>
                <TableCell>{data.status}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(data.did);
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        onClick={handleExportCSV}
        variant="contained"
        color="primary"
        style={{ margin: "10px" }}
      >
        Export as CSV
      </Button>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteConfirm}
        onClose={handleCloseDeleteConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirm} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DIDList;
