import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';
import { ArcElement, Tooltip, Legend, Chart as ChartJS, PieController } from 'chart.js';
import { Card, CardContent, Grid, Typography } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend, PieController);

function Home() {
  const authentication = localStorage.getItem('authentication');
  
  const initialChartData = {
    labels: [],
    datasets: [{
      label: '',
      data: [],
      backgroundColor: [],
      borderColor: [],
      borderWidth: 1,
    }]
  };

  const [serverIPData, setServerIPData] = useState(initialChartData);
  const [ipAddressesData, setipAddressesData] = useState([]);
  const [registeredDIDsData, setRegisteredDIDsData] = useState(initialChartData);
  const [unregisteredDIDsData, setUnregisteredDIDsData] = useState(initialChartData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://tfkroute.geekabhishek.com/did/${authentication}/dashboard`);
        const data = response.data;

        const ipAddresses = Object.keys(data);
        setipAddressesData(ipAddresses);
        const totals = ipAddresses.map(ip => data[ip].total);
        const registered = ipAddresses.map(ip => data[ip].registered);
        const unregistered = ipAddresses.map(ip => data[ip].unregistered);

        setServerIPData({
          labels: ipAddresses,
          datasets: [{
            label: 'Current DIDs per ServerIP',
            data: totals,
            backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)'],
            borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
            borderWidth: 1,
          }]
        });

        setRegisteredDIDsData({
          labels: ['Registered', 'Unregistered'],
          datasets: [{
            label: `${ipAddresses[0]} DIDs`,
            data: [registered[0], unregistered[0]],
            backgroundColor: ['rgba(75, 192, 192, 0.5)', 'rgba(153, 102, 255, 0.5)'],
            borderColor: ['rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)'],
            borderWidth: 1,
          }]
        });

        setUnregisteredDIDsData({
          labels: ['Registered', 'Unregistered'],
          datasets: [{
            label: `${ipAddresses[1]} DIDs`,
            data: [registered[1], unregistered[1]],
            backgroundColor: ['rgba(255, 206, 86, 0.5)', 'rgba(255, 159, 64, 0.5)'],
            borderColor: ['rgba(255, 206, 86, 1)', 'rgba(255, 159, 64, 1)'],
            borderWidth: 1,
          }]
        });

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [authentication]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography variant="h6">DIDs per ServerIP</Typography>
            <Pie data={serverIPData} />
          </CardContent>
        </Card>
      </Grid>
      {ipAddressesData.length > 0 && (
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">{`${ipAddressesData[0]} DIDs`}</Typography>
              <Pie data={registeredDIDsData} />
            </CardContent>
          </Card>
        </Grid>
      )}
      {ipAddressesData.length > 1 && (
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">{`${ipAddressesData[1]} DIDs`}</Typography>
              <Pie data={unregisteredDIDsData} />
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
}

export default Home;