import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Card, CardContent, TextField, Grid, Button,
  Typography, Dialog, DialogActions, DialogContent,
  DialogTitle
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import GetAppIcon from '@mui/icons-material/GetApp';
import SaveIcon from '@mui/icons-material/Save';

function ManageDID() {
  const authentication = localStorage.getItem('authentication');
  const { did } = useParams();
  const navigate = useNavigate();
  const [didData, setDidData] = useState(null);
  const [routeIP, setRouteIP] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    const fetchDidData = async () => {
      try {
        const response = await axios.get(`https://tfkroute.geekabhishek.com/did/${authentication}/did/${did}`);
        setDidData(response.data.did);
      } catch (error) {
        console.error("Error fetching DID data:", error);
      }
    };

    fetchDidData();
  }, [did,authentication]);

  const handleInputChange = (e) => setRouteIP(e.target.value);

  const handleSubmit = async () => {
    const dataToPost = {
      number: didData.number,
      did: didData.did,
      domain: didData.domain,
      password: didData.password,
      switchIP: routeIP,
      serverIP: didData.server_ip
    };
    try {
      await axios.post(`https://tfkroute.geekabhishek.com/update/${authentication}/updateDID`, dataToPost);
      console.log('Data submitted successfully');
      setRouteIP(''); // Correctly reset the state
      navigate(`/DIDList`);
      
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  const handleOpenDeleteDialog = () => setOpenDeleteDialog(true);
  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const handleDelete = async () => {
    try {
      await axios.delete(`https://tfkroute.geekabhishek.com/dElEtEdId/${authentication}/${did}`);
      console.log('DID deleted successfully');
      handleCloseDeleteDialog();
      navigate(`/DIDList`);

      // Optionally, redirect or fetch data again to update UI
    } catch (error) {
      console.error("Error deleting DID:", error);
      handleCloseDeleteDialog();
      navigate(`/DIDList`);
    }
  };

  // Placeholder functions for unimplemented features
  const handleFetchLog = () => console.log('Fetch Log not implemented.');
  const handleCopyDetails = () => console.log('Copy Details not implemented.');

  if (!didData) {
    return <div>Loading...</div>;
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h6">Manage DID</Typography>
            <TextField label="ID" variant="outlined" fullWidth value={didData.id} InputProps={{ readOnly: true }} />
            <TextField label="Number" variant="outlined" fullWidth value={didData.number} InputProps={{ readOnly: true }} />
            <TextField label="DID" variant="outlined" fullWidth value={didData.did} InputProps={{ readOnly: true }} />
            <TextField label="Server IP" variant="outlined" fullWidth value={didData.server_ip} InputProps={{ readOnly: true }} />
            <TextField label="Current Route IP" variant="outlined" fullWidth value={didData.switch_ip} InputProps={{ readOnly: true }} />
            <TextField label="Password" variant="outlined" fullWidth value={didData.password} InputProps={{ readOnly: true }} />
            <TextField label="Domain" variant="outlined" fullWidth value={didData.domain} InputProps={{ readOnly: true }} />
            <TextField label="Last Check Time" variant="outlined" fullWidth value={didData.last_check_time} InputProps={{ readOnly: true }} />
            <TextField label="Status" variant="outlined" fullWidth value={didData.status} InputProps={{ readOnly: true }} />
          </CardContent>
        </Card>
        <Card style={{ marginTop: '20px' }}>
          <CardContent>
            <TextField
              label="New Route IP"
              variant="outlined"
              fullWidth
              value={routeIP}
              onChange={handleInputChange}
            />
          </CardContent>
        </Card>
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          <Grid item xs={6}>
            <Button startIcon={<SaveIcon />} onClick={handleSubmit} variant="contained" fullWidth>Submit</Button>
          </Grid>
          <Grid item xs={6}>
            <Button startIcon={<DeleteIcon />} onClick={handleOpenDeleteDialog} variant="contained" color="error" fullWidth>Delete</Button>
          </Grid>
          <Grid item xs={6}>
            <Button startIcon={<GetAppIcon />} onClick={handleFetchLog} variant="contained" fullWidth>Fetch Log</Button>
          </Grid>
          <Grid item xs={6}>
            <Button startIcon={<FileCopyIcon />} onClick={handleCopyDetails} variant="contained" fullWidth>Copy Details</Button>
          </Grid>
        </Grid>
      </Grid>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this DID?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={handleDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default ManageDID;